import React, { useEffect, useState } from 'react'
import { useInterval } from '../../utils'

export default function Title({ children, variant, style, className, id, white }) {
  const styles = {
    small: 'text-lg',
    default: 'text-xl',
    medium: 'text-2xl',
    large: 'text-3xl',
    'extra-large': 'text-4xl'
  }
  return (
    <div
      id={id}
      style ={{...style, fontFamily: 'PPFormula', letterSpacing: 1 }}
      className={`${styles[variant]} flex justify-center items-center uppercase font-bold mt-2 mb-2 relative leading-tight ${className} ${white ? 'text-white' : 'text-gray-800'}`}>
      {children}
    </div>
  )
}
