import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import { flattenObject, language } from '../../utils'

export default function I18n({ children, markdown = false, html = false, variables }) {
  const { strapi } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          wordings
          language
        }
      }
    }
  `)
  const locale = strapi.locales.find(l => l.language === 'fr-lalalab')
  if (!locale) return <span>{children}</span>
  const flatWordings = flattenObject(locale.wordings)
  let currentKey = flatWordings[children]
  if (!currentKey) return <span>{children}</span>
  if (variables) {
    const func = new Function(...Object.keys(variables), `return \`${currentKey}\``)
    currentKey = func(...Object.keys(variables).map(k => variables[k]))
  }
  if (markdown) return <ReactMarkdown>{currentKey}</ReactMarkdown>
  if (html) return <ReactMarkdownWithHtml children={currentKey} allowDangerousHtml />
  return <span>{currentKey}</span>
}